import React from 'react'
import '../styles/footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { faInstagram,faFacebook, faTiktok, faWhatsapp, } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';




function Navfooter() {
    const scrollToTop = () =>{
        window.scrollTo({ top: 0 });
      }

  return (
    <div className='footer'>
        <div className='footer-nav'>
            <Link to='/' onClick={scrollToTop}><img src='/images/eve-logo.png' alt='logo'style={{width:'80px', height:'80px'}}/></Link>
            
            <div id='social-links'>
                {/**decided to duplicate the icons so to change their sizes in responsive by making one div invisible */}
                <div className='large'>
                    <Link to='https://www.instagram.com/abi_eve_planning.co?igsh=MWtyZnBxc3V0Y2R6dQ%3D%3D&utm_source=qr' 
                    target="_blank" rel="noopener noreferrer"
                    className='iconn-link'><FontAwesomeIcon icon={faInstagram} style={{ color: '#C7AE6A ', fontSize:'25px' }}/></Link>
                
                    <Link to='https://www.facebook.com/profile.php?id=61550107766161&mibextid=LQQJ4d'
                    target="_blank" rel="noopener noreferrer"
                    
                    className='iconn-link'> <FontAwesomeIcon icon={faFacebook} style={{ color: '#C7AE6A ', fontSize:'25px' }}/></Link>

                    <Link to='https://www.tiktok.com/@blessing_above?_t=8nzJr2W2ffT&_r=1'  target="_blank" rel="noopener noreferrer"
                    className='iconn-link'><FontAwesomeIcon icon={faTiktok} style={{ color: '#C7AE6A ', fontSize:'25px' }}/>
                    </Link>

                    <Link to="http://wa.me/+14434228448" target="_blank" rel="noopener noreferrer" className='iconn-link'> <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#C7AE6A ', fontSize:'25px' }}/>
                    </Link>
                </div>

                <div className='smalll'>

                    <Link to='https://www.instagram.com/abi_eve_planning.co?igsh=MWtyZnBxc3V0Y2R6dQ%3D%3D&utm_source=qr' 
                    target="_blank" rel="noopener noreferrer" className='iconn-link'><FontAwesomeIcon icon={faInstagram} style={{ color: '#C7AE6A ', fontSize:'18px' }}/></Link>
                
                    <Link to='https://www.facebook.com/profile.php?id=61550107766161&mibextid=LQQJ4d'
                    target="_blank" rel="noopener noreferrer"
                    
                    className='iconn-link'> <FontAwesomeIcon icon={faFacebook} style={{ color: '#C7AE6A ', fontSize:'18px' }}/></Link>

                    <Link to='https://www.tiktok.com/@blessing_above?_t=8nzJr2W2ffT&_r=1'  target="_blank" rel="noopener noreferrer"
                    className='iconn-link'><FontAwesomeIcon icon={faTiktok} style={{ color: '#C7AE6A ', fontSize:'18px' }}/>
                    </Link>

                    <Link to="http://wa.me/+14434228448" target="_blank" rel="noopener noreferrer" className='iconn-link'> <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#C7AE6A ', fontSize:'18px' }}/>
                    </Link>
                </div>
            </div>
            <div id='copyRight'>
                <div className='copyLarge'>
                    <FontAwesomeIcon icon={faCopyright} style={{ color: '#C7AE6A ', fontSize:'25px' }}/>
                </div>

                <div className='copySmall'>
                    <FontAwesomeIcon icon={faCopyright} style={{ color: '#C7AE6A ', fontSize:'8px' }}/>
                </div>
                
                <p>2024 ABI-EVE PLANNING CO.</p>
            </div>
        </div>
    </div>
  )
}

export default Navfooter