import React from 'react';
import { motion } from 'framer-motion';

const transition = (OgComponent) => {


  const subtleDimProps = {
    initial: { opacity: 0.3 }, 
    animate: { opacity: 1 }, 
    exit: { opacity: 0 }, 
    transition: {
      duration: 0.8, 
      ease: 'easeInOut', 
      yoyo: 0, 
    },
  };

  return (props) => (
    <motion.div
    className='subtle-dim'
    {...subtleDimProps}
    {...props.transition}
  >
      <OgComponent {...props} />
    </motion.div>
  );
};

export default transition;
