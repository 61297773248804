import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';
import transition from '../components/transition';
import { Media } from '../components/Media';
import { motion } from 'framer-motion';
import '../styles/gallery2.css';

function Gallery() {
  const [data, setData] = useState([]);
  const [collection, setCollection] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFilter, setActiveFilter] = useState('All');
  const itemsPerPage = 12;

  
  
  useEffect(() => {
    setData(Media);
    setCollection([...new Set(Media.map((item) => item.title))]);

  }, []);


  const gallery_filter = (itemData) => {
    const filterData = Media.filter((item) => item.title === itemData);
    setData(filterData);
    setCurrentPage(1);
    setActiveFilter(itemData);
    
  };

  const handlePagination = (action) => {
    if (action === 'next' && currentPage < Math.ceil(data.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    } else if (action === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getVisibleItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const scrollToTop = () =>{
    window.scrollTo({ top: 0 });
  }

  return (
    <div>

      <div  className="galleryHead">
        <h1 id='galleryHead'  data-aos="fade-up" data-aos-duration="1000">
          Gallery
        </h1>
      </div>      

      <div className="galleryWrapper">
        <div className="filterItem" data-aos="fade-up" data-aos-duration="1000" data-aos-delay='500'>
          <ul>
            <li>
              <button 
                className={activeFilter === 'All' ? 'active' : 'normal'} 
                onClick={() => {
                  setData(Media);
                  setActiveFilter('All'); 
                }}>All</button>
            </li>
            {collection.map((item) => (
              <li key={item}>
                <button 
                 className={activeFilter === item ? 'active' : 'normal'}
                onClick={() => gallery_filter(item)}>{item}</button>
              </li>
            ))}
          </ul>
        </div>
        <div className="galleryContainer" data-aos="fade-up" data-aos-duration="1000" data-aos-delay='1000'>
          {/** 
          {getVisibleItems().map((item) => (

            <motion.div key={item.id} className="galleryItem" 
                
               >
              {item.title === 'Video' ? (
                <ReactPlayer url={item.url} controls={true} className="vidivid" width="250px"/>
              ) : (
                <img src={item.image} alt={item.title || 'Image'} className="imagess" />
              )}
            </motion.div>

          ))}
         */}
         {getVisibleItems().map((item) => (
            <motion.div key={item.id} className="galleryItem">
            {item.url ? ( // Check for URL property
            <ReactPlayer url={item.url} controls={true} className="vidivid" width="300px" />
            ) : (
            <img src={item.image} alt={item.title || 'Image'} className="imagess" />
        )}
  </motion.div>
))}
        </div>
        {data.length > itemsPerPage && (
            <div className="pagination" >
              <button disabled={currentPage === 1} onClick={() => handlePagination('prev')}>
                Previous
              </button>
              <button
                disabled={currentPage === Math.ceil(data.length / itemsPerPage)}
                onClick={() => handlePagination('next')}
              >
                More
              </button>
            </div>
          )}
      </div>

      <div className='link' data-aos="fade-up" data-aos-duration="1000" data-aos-delay='200' id='gallery-link'>
        <p><Link to='/contact' onClick={scrollToTop} className='link-word'>Let's make your event dream a reality. Contact us for a free consultation!</Link></p>
        </div>
      <hr></hr>
    </div>
  );
}

export default transition(Gallery);
