import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/error.css'
import transition from '../components/transition'
function Error() {
  return (
    <div className='error'>

        <h1>ERROR 404: PAGE DOES NOT EXIST</h1>
        
        <p><Link to='/' className='errorhome'>GO TO HOME  </Link></p>
        
    </div>
  )
}

export default transition(Error)