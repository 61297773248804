import React, {useState} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom'
import '../styles/navbar.css';


function Navbar() {
    const [clicked, setClicked] = useState(false)
    const navigate = useNavigate()
 
  const handleClick = () => {
    setClicked(!clicked);
  };
  
  const handleLinkClick = () => {
    setClicked(false); 
  };

  const logoClick =() =>{
    setClicked(false); 
    navigate('/')
    console.log('Hola')
  }
  

  return (
    
        
    <div>

<div className='large-view'>
      <nav >
          <div>
            <ul>
              <li id='list-items' ><Link to='/about' className='nav-link'>About</Link></li>

              <li id='list-items'><Link to='/services' className='nav-link'>Our Services</Link></li>

              <li className='Main-logo'> <Link to='/'><img src='../images/eve-logo.png' alt='logo' style={{width:'180px', height:'180px'}}/></Link></li>

              <li id='list-items' ><Link to='/gallery' className='nav-link'>Gallery</Link></li>

              <li id='list-items' ><Link to='/contact' className='nav-link'>Contact</Link></li>
            </ul>
          </div>
          <div className='mobile'>
            <FontAwesomeIcon
              icon={clicked ? faXmark : faBars}
              onClick={handleClick} 
            />
          </div>
        </nav>
      </div>

        {/**Attempt to make a responsive nav for mobile view */}
        <div className='responsive-mobile'>
      <div className='mobile-nav' >
          <div>
                <li className='Main-logo'> <Link onClick={logoClick}><img src='../images/eve-logo.png' alt='logo' style={{width:'180px', height:'180px'}}/></Link></li>

          </div>
  {clicked && (
              <ul className={`dark-overlay ${clicked ? '' : 'initial-hidden'}`}
              style={{ transform: clicked ? 'translateX(0)' : 'translateX(100%)' }}>
                

              <li id='list-items' ><Link to='/about' className='nav-link' onClick={handleLinkClick}>About</Link></li>

              <li id='list-items'><Link to='/services' className='nav-link' onClick={handleLinkClick}>Our Services</Link></li>

              <li id='list-items' ><Link to='/gallery' className='nav-link' onClick={handleLinkClick}>Gallery</Link></li>

              <li id='list-items' ><Link to='/contact' className='nav-link' onClick={handleLinkClick}>Contact</Link></li>
              </ul>
          )}

          
          

          <div className='mobile-bar'>
            <FontAwesomeIcon
              icon={clicked ? faXmark : faBars}
              onClick={handleClick} 
              style={{ color: '#C7AE6A ', fontSize:'30px' }} 
            />
          </div>
        </div>
      
      </div>

   
      





       
       
    </div>
  )
}

export default  Navbar