import React from 'react'
import '../styles/about.css'
import { Link } from 'react-router-dom';
import transition from '../components/transition'
import AOS from 'aos';
import 'aos/dist/aos.css'; 


AOS.init(); // Initialize AOS


function About() {

  const scrollToTop = () =>{
    window.scrollTo({ top: 0 });
  }
  
  const imageUrl = process.env.PUBLIC_URL + '/images/about2.jpg';
  const imageU = process.env.PUBLIC_URL + '/images/about3.jpg';


  return (
    <div>
        <div className='coverAbout'>
            <div id='coverHead'>
              <h1 data-aos="fade-up" data-aos-duration="1000" >Abi-Eve</h1>
            </div>
        </div>

        <div className='coverContent'>
          
            <div className='image-container'>
            <img src={imageUrl} alt='eve'className='img1'  data-aos="fade-up" data-aos-duration="1000" />
            </div>
            <div className='paragraphC'>
              <div data-aos="fade-left" data-aos-duration="1000" data-aos-delay='500' id='p-border'>
              <p> Abi Eve Planners was born not in a sterile boardroom, but in the heart of a home, fueled by creativity and a passion for connection. As event planners and enthusiasts, we understand the artistry of transforming ordinary moments into extraordinary memories. This inherent passion for crafting joy and fostering meaningful connections is the cornerstone of our event planning philosophy. Each event we design is more than just a gathering; it's a meticulously curated celebration of life's precious milestones, leaving an enduring impact.</p>

              <p id='paragraphC' >Balancing motherhood and entrepreneurship has honed our ability to navigate challenges with efficiency, organization, and a relentless pursuit of excellence. This unique perspective allows us to approach every event with a keen understanding of our clients' needs and the importance of seamless execution. Whether it’s coordinating intricate details or managing unexpected surprises, our team is committed to delivering flawless events that exceed expectations.</p>
              </div>
          </div>
        </div>
        
        <div className='coverContent' id='coverc'>
          
            <div className='image-container'>
            <img src={imageU} alt='eve'className='img2' data-aos="fade-up" data-aos-duration="1000"/>
            </div>
            <div className='paragraphC' id='second-paragraph'>
              <div id='p-border'  data-aos="fade-right" data-aos-duration="1000" data-aos-delay='300' >
              <p>Beyond crafting unforgettable experiences, Abi Eve Planning Co. is committed to empowering women. We believe in the strength of collaboration and are passionate about supporting other female entrepreneurs in the event industry. Witnessing women thrive inspires us to create a supportive community where every voice is valued.
              </p>

              <p id='paragraphC'> Our team is dedicated to building genuine connections with each client, understanding their unique vision, and transforming their dream events into reality. Let's create something extraordinary together.</p>
              </div>
          </div>       
        
        </div>
        <p className='view-link-p' data-aos="fade-right" data-aos-duration="1000" data-aos-delay='500'><Link to='/services' onClick={scrollToTop} className='view-link'>View Our Services</Link></p>
        

          <hr></hr>






        
        
  
    </div>
  )
}

export default transition(About)