import './App.css';
import "@fontsource/bona-nova"

import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home'
import Error from './pages/Error'
import About from './pages/About'
import Services from './pages/Services'
import Gallery from './pages/Gallery'
import Contact from './pages/Contact'
import Rootlayout from './components/Rootlayout';
//import { AnimatePresence } from 'framer-motion';





const route = createBrowserRouter([
  {path:'/', 
  element: <Rootlayout/>, 
  errorElement: <Error/>,
  children:[
          {path:'/', element: <Home/>},
          {path:'/about', element: <About/>},
          {path:'/services', element: <Services/>},
          {path:'/gallery', element: <Gallery/>},
          {path:'/contact', element: <Contact/>},
          {path:'/error', element: <Error/>}
  ]},

  
])

function App() {

  return (  
  
    <RouterProvider router={route} />
  
  
)
    
  
  
}

export default App