import React from 'react'
import '../styles/homepage.css'
import transition from '../components/transition'
import { useNavigate } from 'react-router-dom';

function Home() {

  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/about');
  };


  return (

    
    <div
    >
      <div >
        <div className='landingpage ddark-overlay' >
          <div>
            {/** <div className= {clicked ? 'main-blur': ''}> */}
            <div className='landingContent'>
              
              <div><h1 data-aos="fade-up" data-aos-duration="1000">Eve Of Perfection</h1> <br></br></div>
            <div>
              
              <button className='getStarted' data-aos="fade-up" data-aos-duration="1000" data-aos-delay='600'
              onClick={handleButtonClick}>
                Get Started

              </button>
              
              </div>
              
            </div>
          </div>
            
            
        </div>
      </div>

        
        
    </div>
  )
}

export default transition(Home)