/*import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './Navbar'
function Rootlayout() {
  return (
    <div>
            <Navbar/>
        <Outlet/>
    </div>
  )
}

export default Rootlayout*/

import React from 'react';
import { Outlet } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import Navbar from './Navbar';
import Navfooter from './Navfooter';

function Rootlayout() {
  

  return (
    <div>
      <Navbar />
      <AnimatePresence mode="wait">
        <Outlet/>
      </AnimatePresence>
      <Navfooter />
    </div>
  );
}

export default Rootlayout;
