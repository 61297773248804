import React from "react";
//const g22= process.env.PUBLIC_URL + '/images/g22.jpg';

const social1= process.env.PUBLIC_URL + '/images/c1.jpg';
const social2= process.env.PUBLIC_URL + '/images/g2.jpg';
const social3= process.env.PUBLIC_URL + '/images/service3.jpg';
const social4= process.env.PUBLIC_URL + '/images/p21.jpg';
const social5= process.env.PUBLIC_URL + '/images/p20.jpg';
const social6= process.env.PUBLIC_URL + '/images/p19.jpg';
const social7= process.env.PUBLIC_URL + '/images/p18.jpg';
const social8= process.env.PUBLIC_URL + '/images/p17.jpg';
//const social9= process.env.PUBLIC_URL + '/images/p1.jpg';
const social10= process.env.PUBLIC_URL + '/images/p16.jpg';
const social11= process.env.PUBLIC_URL + '/images/p12.jpg';
const social12= process.env.PUBLIC_URL + '/images/p9.jpg';
const social13= process.env.PUBLIC_URL + '/images/p6.jpg';
const social14= process.env.PUBLIC_URL + '/images/p5.jpg';
const social15= process.env.PUBLIC_URL + '/images/p4.jpg';
const social16= process.env.PUBLIC_URL + '/images/g16.jpg';
const social17= process.env.PUBLIC_URL + '/images/g15.jpg';
const social18= process.env.PUBLIC_URL + '/images/g14.jpg';
const social19= process.env.PUBLIC_URL + '/images/g13.jpg';
const social20= process.env.PUBLIC_URL + '/images/g2.jpg';
const social21= process.env.PUBLIC_URL + '/images/g6.jpg';

const cor1 = process.env.PUBLIC_URL + '/images/service7.jpg';
const cor2 = process.env.PUBLIC_URL + '/images/service1.jpg';
const cor3 = process.env.PUBLIC_URL + '/images/p22.jpg';
const cor4 = process.env.PUBLIC_URL + '/images/p10.jpg';
const cor5 = process.env.PUBLIC_URL + '/images/p7.jpg';
const cor6 = process.env.PUBLIC_URL + '/images/p3.jpg';
const cor7= process.env.PUBLIC_URL + '/images/p2.jpg';
const cor8= process.env.PUBLIC_URL + '/images/p1.jpg';
const cor9= process.env.PUBLIC_URL + '/images/landing.jpg';
const cor10= process.env.PUBLIC_URL + '/images/g1.jpg';
const cor11 = process.env.PUBLIC_URL + '/images/g3.jpg';
const cor12 = process.env.PUBLIC_URL + '/images/g10.jpg';


const wed1 = process.env.PUBLIC_URL + '/images/service2.jpg';
const wed2 = process.env.PUBLIC_URL + '/images/p24.jpg';
const wed3 = process.env.PUBLIC_URL + '/images/p23.jpg';
const wed4 = process.env.PUBLIC_URL + '/images/g10.jpg';
const wed5 = process.env.PUBLIC_URL + '/images/g4.jpg';

const new1 = process.env.PUBLIC_URL + '/images/new1.jpg';
const new2 = process.env.PUBLIC_URL + '/images/new2.jpg';
const new3 = process.env.PUBLIC_URL + '/images/new3.jpg';
const new4 = process.env.PUBLIC_URL + '/images/new4.jpg';
const new5 = process.env.PUBLIC_URL + '/images/new5.jpg';
const new6 = process.env.PUBLIC_URL + '/images/new6.jpg';
const new7 = process.env.PUBLIC_URL + '/images/new7.jpg';
const new8 = process.env.PUBLIC_URL + '/images/new8.jpg';
const new9 = process.env.PUBLIC_URL + '/images/new9.jpg';
const new10 = process.env.PUBLIC_URL + '/images/new10.jpg';
const new11 = process.env.PUBLIC_URL + '/images/new11.jpg';
const new15 = process.env.PUBLIC_URL + '/images/new15.jpg';
const new16 = process.env.PUBLIC_URL + '/images/new16.jpg';
const new17 = process.env.PUBLIC_URL + '/images/new17.jpg';
const new18 = process.env.PUBLIC_URL + '/images/new18.jpg';
const new19 = process.env.PUBLIC_URL + '/images/new19.jpg';

const new13 = process.env.PUBLIC_URL + '/images/new13.jpg';
const new14 = process.env.PUBLIC_URL + '/images/new14.jpg';



const community = process.env.PUBLIC_URL + '/images/community.jpg';
const community2 = process.env.PUBLIC_URL + '/images/community2.jpg';

const upcomingEvents1 = process.env.PUBLIC_URL + '/images/upcomingEvents.jpg';
const upcomingEvents2 = process.env.PUBLIC_URL + '/images/upcomingEvents2.jpg';

//const video2 = './videos/v2.mp4';

export const Media = [
    {
        id:1,
        title: 'Social',
        image: cor4
    },
    {
        id:45,
        title: 'Social',
        image: new2
    },
    {
        id:2,
        title: 'Social',
        image: cor1
    },
   
    {
        id: 3,
        title: 'Social',
        image: social2
    },
    {
        id:4,
        title: 'Social',
        image: social5
    },
    {
        id:5,
        title: 'Social',
        image: social3
    },
    {
        id: 6,
        title: 'Social',
        image: cor3
    },
    {
        id:7,
        title: 'Social',
        image: social4
    },
    {
        id:8,
        title: 'Social',
        image: social1
    },
    {
        id:9,
        title:  'Social',
        image: cor2
    },
    {
        id: 10,
        title:  'Social',
        url: '/vidd/v1.mp4',
    },
    {
        id:11,
        title:  'Social',
        image: cor5
    },
    {
        id:12,
        title: 'Social',
        image: social6
    },
    {
        id:13,
        title:  'Social',
        image:  cor6
    },
    {
        id:14,
        title: 'Social',
        image: social7
    },
    {
        id:15,
        title:  'Social',
        image: cor7
    },
    {
        id:16,
        title: 'Social',
        image: social8
    },
    {
        id:17,
        title:  'Social',
        image: cor8
    },
    {
        id:18,
        title: 'Social',
        image: social14
    },
    {
        id:20,
        title: 'Social',
        image: social10
    },
    {
        id:22,
        title: 'Social',
        image: social11
    },
    {
        id:24,
        title: 'Social',
        image: social12
    },
    {
        id:26,
        title: 'Social',
        image: social13
    },
    {
        id:19,
        title: 'Social',
        image: cor9
    },
    {
        id:21,
        title:  'Social',
        image: cor10
    },
    {
        id: 23,
        title: 'Social',
        url: '/vidd/v2.mp4',
    },
    {
        id:25,
        title:  'Social',
        image: cor11
    },
    {
        id:28,
        title: 'Social',
        image: social15
    },
    {
        id:30,
        title: 'Social',
        image: social16
    },
    {
        id:27,
        title: 'Social',
        image: cor12
    },
    {
        id:29,
        title: 'Social',
        image: wed1
    },
    {
        id: 31,
        title: 'Social',
        url: '/vidd/v4.mp4',
    },
    {
         id:32,
        title: 'Social',
        image: social17
    },
    {
        id:34,
        title: 'Social',
        image: social18
    },
    {
        id:36,
        title: 'Social',
        image: social19
    },
    {
        id: 33,
        title: 'Social',
        url: '/vidd/v5.mp4',
    },
    {
        id:35,
        title: 'Social',
        image: social20
    },
    {
        id:37,
        title: 'Social',
        image: social21
    },
    {
        id: 38,
        title:  'Social',
        url: '/vidd/v3.mp4',
    },
    {
        id:39,
        title: 'Social',
        image: wed4
    },
    {
        id:40,
        title: 'Social',
        image: wed2
    },
    {
        id: 41,
        title: 'Social',
        url: '/vidd/v6.mp4',
    },
    {
        id:46,
        title: 'Social',
        image: new3
    },
    {
        id:42,
        title: 'Social',
        image: wed3
    },
    {
        id:43,
        title: 'Social',
        image: wed5
    },
    {
        id:44,
        title: 'Community Events',
        image: new1
    },
   
    /*
    {
        id:45,
        title: 'Latest Events',
        image: new13
    },*/
    {
        id:45,
        title: 'Community Events',
        image: new2
    },
    {
        id:46,
        title: 'Community Events',
        image: new3
    },
   
    {
        id:47,
        title: 'Community Events',
        image: new4
    },
    {
        id:48,
        title: 'Community Events',
        image: new5
    },
    
    {
        id:49,
        title: 'Community Events',
        image: new6
    },
    {
        id:50,
        title: 'Community Events',
        image: new7
    },
   
    {
        id:51,
        title: 'Community Events',
        image: new8
    },
    {
        id:52,
        title: 'Community Events',
        image: new9
    },
   
    {
        id:53,
        title: 'Community Events',
        image: new10
    },
    {
        id:54,
        title: 'Community Events',
        image: new11
    },
   
    {
        id:55,
        title: 'Community Events',
        image: new15
    },
    {
        id:56,
        title: 'Community Events',
        image: new16
    },
    
    {
        id:57,
        title: 'Community Events',
        image: new17
    },
    {
        id:58,
        title: 'Community Events',
        image: new18
    },
    
    {
        id:59,
        title: 'Community Events',
        image: new19
    },
    {
        id:60,
        title: 'Community Events',
        url: '/vidd/v7.mp4',
    },
    {
        id: 61 ,
        title: 'Community Events',
        url: '/vidd/v8.mp4',
    },
    {
        id: 62 ,
        title: 'Community Events',
        url: '/vidd/v9.mp4',
    },
    {
        id: 63,
        title: 'Community Events',
        url: '/vidd/v10.mp4',
    },
    {
        id: 64,
        title: 'Community Events',
        url: '/vidd/v11.mp4',
    },
    {
        id:65 ,
        title: 'Community Events',
        url: '/vidd/v12.mp4',
    },
    {
        id: 66,
        title: 'Community Events',
        url: '/vidd/v13.mp4',
    },
    {
        id: 67,
        title: 'Community Events',
        url: '/vidd/v14.mp4',
    },
    {
        id: 68,
        title: 'Upcoming Events',
        image: upcomingEvents1,
    },
    {
        id:69,
        title: 'Upcoming Events',
        image: upcomingEvents2
    },
    {
        id:70,
        title: 'Community Events',
        image: new13
    },
    {
        id:71,
        title: 'Community Events',
        image: new14
    },
    
    
    

]

