
import React, {useRef} from 'react'
import '../styles/contact.css'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import transition from '../components/transition'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init(); // Initialize AOS

function Contact() {

  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
  
    emailjs
      .sendForm('service_6hhxyt9', 'template_brersk7', form.current, {
        publicKey: '8Xzqmtt5Zk6vdOoWd',
      })
      .then(
        () => {
          console.log('SUCCESS')
          toast.success('Your message has been sent successfully!');
          
        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error('There was an error sending your message. Please try again later.');
        },
      );
      e.target.reset()
  } 
 
  
 



  return (
    <div>
      <div className='coverContact'>
            <div id='coverHead'>
              <h1 data-aos="fade-up" data-aos-duration="1000">Make Your Events Experience 
              Unforgettable</h1>
            </div>
      </div>

      <div className='classIntro' data-aos="fade-up" data-aos-duration="1000" data-aos-delay='200'>
        <p id='large'>Get in touch</p>
        <p id='less'>Lets talk about your Events</p>
      </div>

    
      
      <form ref={form} onSubmit={sendEmail} data-aos="fade-up" data-aos-duration="1000" data-aos-delay='500'> 
        
        <div id='form-input'>

        {/**<p id='error-msg'>{errors.name?.message}</p> */}

        <input
         type='text' 
         id='name'
         placeholder='Name'
         name='user_name' required
        ></input>
        
        
        <input
         type='tel' 
         id='number'
         name='user_number' required
         placeholder='Telephone'
        ></input>



        <input
         type='email' 
         id='email'
         placeholder='E-mail'
         name='user_email' required
        ></input>

        
             

           

          <div id='enquire'>
          <textarea
            placeholder='Enter your message'
            name='message'
             // Adjust the number of rows as needed
             // Adjust the number of columns as needed
              />
          
            
          </div>
        </div>
        <button id='btn' type= 'submit'>Send</button>
      </form>

      
      <hr></hr>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      
    </div>
    
  )
}

export default transition(Contact)
 
 /*
import React, {useRef} from 'react'
import emailjs from '@emailjs/browser';
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'
function Contact() {

  const form = useRef()

  const form1 = useForm()
  const {register, control, handleSubmit, formState} = form
  const {errors} = formState



  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_6hhxyt9', 'template_brersk7', form.current, {
        publicKey: '8Xzqmtt5Zk6vdOoWd',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
      e.target.reset()
  };

  return (
    <div>
      <form ref={form} onSubmit={sendEmail} >
        <input type='text'
         placeholder='Name'
         name='user_name' required
        />

        <input type='email'
         placeholder='Email'
         name='user_email' required
        />

        <input type='tel'
         placeholder='Telephone'
         name='user_number' required
        />

        <textarea 
          name='message'
          cols='30'
          rows='10'
        />
        
        <button type='submit'>Send</button>
      </form>

      <DevTool control={control}/>
    </div>
  )
}

export default Contact*/