import React from 'react'
import '../styles/services.css'
import { Link } from 'react-router-dom';
import transition from '../components/transition'
import AOS from 'aos';
import 'aos/dist/aos.css'; 

AOS.init(); // Initialize AOS

function Services() {

  const scrollToTop = () =>{
    window.scrollTo({ top: 0 });
  }
  const imageUrl = process.env.PUBLIC_URL + '/images/service2.jpg';
  const imageUr = process.env.PUBLIC_URL + '/images/service7.jpg';
  const imageU = process.env.PUBLIC_URL + '/images/service3.jpg';

  const review1 = process.env.PUBLIC_URL + '/images/rr2.jpg';
  const review2 = process.env.PUBLIC_URL + '/images/rr3.jpg';
  const review3 = process.env.PUBLIC_URL + '/images/rr1.jpg';
  return (
    <div>
        <div className='service-cover'>
          <div id='service-head'>
            <h1 data-aos="fade-up" data-aos-duration="1000">Our Services</h1>
          </div>
        </div>

        <div className='mini-intro'data-aos="fade-right" data-aos-duration="1000" data-aos-delay='500'  >
          <p>A seamless fusion of style, organization, and unforgettable moments. That's the magic we create at</p>
          <p id='minni'>ABI-EVE</p>
        </div>


        <div className='service-offered'>

          <div id='wedding'>
            <div id='wedding-h2'>
            <h2 data-aos="fade-right" data-aos-duration="1000" data-aos-delay='100' >Weddings</h2>
            </div>
            
            <div className='imagee'>
            <img src={imageUrl} alt='eve'className='weddingImg' id='weddingImg1' data-aos="fade-right" data-aos-duration="1000" data-aos-delay='100' />
            </div>
            
          </div>

          <div id='wedding'>
            <div id='wedding-h2' data-aos="fade-right" data-aos-duration="1000" data-aos-delay='300'>
            <h2  >Corporate Events</h2>
            </div>
            <div className='imagee'>
            <img src={imageUr} alt='eve'className='weddingImg ' id='weddingImg2' data-aos="fade-right" data-aos-duration="1000" data-aos-delay='300' />
            </div>
            
          </div>

          <div id='wedding'>
            <div id='wedding-h2'>
            <h2 data-aos="fade-right" data-aos-duration="1000" data-aos-delay='500' >Social Gatherings</h2>
            </div>
            <div className='imagee'>
            <img src={imageU} alt='eve'className='weddingImg' id='weddingImg3' data-aos="fade-right" data-aos-duration="1000" data-aos-delay='500' />
            </div>
           
          </div>

          {/** Reviews*/}

        </div>



      <div className='afterServices'>
        
          <p data-aos="fade-right" data-aos-duration="1000" data-aos-delay='200' >
          We transform your vision into reality, crafting exceptional experiences for corporate events, dazzling parties, and dream weddings.  We handle every detail, from venue selection to flawless execution, leaving a lasting impression on your guests.
          <br></br><br></br>
          We turn your dreams into reality, no matter your style or budget. We'll listen closely to your vision and use our creativity to bring it to life in a stunning way
          </p>
        
      </div>




      <div className='Reviews'>

        <div className='review1' data-aos="fade-up" data-aos-duration="1000" data-aos-delay='100'>
          <div className='review-img'>
            <img src={review1} alt='eve'className='reviewImg'/>
          </div>
          <div className='review-words'>
            <p>
              “Where do I even begin?  Working with ABI-EVE was an absolute dream!  From the very first consultation, they took the time to understand exactly what I envisioned for my event. They didn't just listen to my ideas, they built upon them, suggesting creative solutions and stunning design concepts that I never would have thought of myself.
              The entire planning process was completely stress-free.  They handled every detail with such professionalism and attention to detail, it felt like a weight had been lifted off my shoulders.  I knew I could completely trust them to make everything perfect, and perfect it was!
              The final event was beyond anything I could have imagined.  It was flawless, stylish, and truly captured my essence.  My guests are still raving about it!  If you're looking for event planners who will go above and beyond to make your vision a reality, then look no further than ABI-EVE.  Thank you for making my event so unforgettable!"
              
              <br></br>

            -Clara
            </p>
          </div>
        </div>

        <div className='review1' id='review1' data-aos="fade-up" data-aos-duration="1000" data-aos-delay='300'>
          <div className='review-img'>
            <img src={review2} alt='eve'className='reviewImg'/>
          </div>
          <div className='review-words' id='short'>
            <p>
            “If you're looking for an event planner who is organized, professional, and makes the entire process stress-free, look no further than ABI-EVE. They recently helped me plan my birthday party and it was an absolute success. ABI-EVE didn't miss a beat, from Decoration to buffet, everything was well planned and executed. She made sure I was well informed every step of the way so we do not face any problem. She delivered her duties diligently and showed high signs of professionalism. I would choose ABI-EVE over and over again.”

              <br></br>
              <br></br>
            -Bernabella
            </p>
          </div>
        </div>

        <div className='review1' id='#review3' data-aos="fade-up" data-aos-duration="1000" data-aos-delay='500'>
          <div className='review-img'>
            <img src={review3} alt='eve'className='reviewImg'/>
          </div>
          <div className='review-words' id='short' >
            <p>
            “As a mom, planning a birthday party can be stressful, especially when it comes to choosing a theme! I had no clue where to start, the options seemed endless. With Abi-Eve's guidance, I went from feeling lost to having a fantastic theme in no time!. Thanks to Abi-Eve, my child's birthday party was a huge success! The theme was perfect, and everyone had a blast. If you're a  parent struggling with party themes, I highly recommend Abi-Eve! They'll help you create a birthday party your child will love.”
              <br></br>
              <br></br>

              -Patricia
            </p>
          </div>
        </div>

      </div>
        <div className='link' data-aos="fade-up" data-aos-duration="1000" data-aos-delay='200'>
        <p><Link to='/gallery' onClick={scrollToTop} className='link-word'>Curious to see how we bring these services to life? Explore our gallery</Link></p>
        </div>
        
        <hr></hr>

        

    </div>
  )
}

export default transition(Services)